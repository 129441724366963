"use client";
import { ICONS, IconNames } from "./constants";
import { twMerge } from "tailwind-merge";
import useTheme from "@/shared/hooks/useTheme";
import { EndemicImage } from "../Image";

interface Props {
  iconName: IconNames;
  className?: string;
  width?: number;
  height?: number;
  alt?: string;
}

const ThemedIcon = ({ iconName, className, width, height, alt }: Props) => {
  const theme = useTheme();
  const Icon = ICONS[iconName][theme];

  return (
    <div
      className={twMerge(
        "relative",
        className,
        width && `w-[${width}px]`,
        height && `h-[${height}px]`,
      )}
    >
      <EndemicImage src={Icon} fill alt={alt || `${iconName}-icon`} />
    </div>
  );
};

export default ThemedIcon;
